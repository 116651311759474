@tailwind base;
@tailwind components;
@tailwind utilities;

.radix-themes {
  --focus-8: #0f172a !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: Grotesk, sans-serif;
  line-height: 120%;
}

.text-gradient {
  background: linear-gradient(111deg, #f2c553 13.59%, #fff 82.2%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* mobile version */
@media (max-width: 768px) {
  .intercom-launcher {
    bottom: 64px !important;
  }
}

@media (hover: hover) and (pointer: fine) {
  [vaul-drawer] {
    user-select: text !important;
  }
}
